@import "bootstrap";

body {
    /*background-image: url("/assets/images/backgrounds/bkgr_Curro@2x.jpg");
    background-repeat: repeat;*/
    background: url(/assets/images/backgrounds/bkgr_Curro@2x.jpg) no-repeat center center fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
}

.login-button,.login-button:hover {
    color: white;
    background-color: black;
    border-color: white;
}

input[type="password"]:focus,
input[type="email"]:focus {
    border-color: #f4bf3a;
    box-shadow: 0 0 8px #f2be4f;
    outline: 0 none;
}
